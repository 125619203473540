export default {
  new_api_url: 'https://gateway.delicity.io',
  base_url: 'https://api.delicity.co',
  login_url: 'https://login.delicity.co',
  auth_client_secret: '5CbbzZQBDEfk3CG0sCc0DQcTmsYgIPoKPRmlcTLr',
  auth_client_id: 2,
  google_maps_api: 'AIzaSyB2rncLZubVOP4WahDioZ2_hudP_IZPvWo',
  echo_auth_url: 'https://api.delicity.co/api/broadcasting/auth',
  pusher_key: '47b9ed1beb0bc4761378',
  type: 'web',
  stripe: 'pk_live_lkeGCwnoZ9j0UJjsYtT9JGI400pgQt5TJW',
  mainHost: 'delicity.co',
  bookingLink: 'https://booking.delicity.com',
  isProd: true,
  gatewayEndpoint: 'https://gateway.delicity.io'
};
